<template>
  <div class="default-layout">
    <div class="body">
      <div class="content">
        <nuxt-page />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.default-layout {
  display: flex;

  > .side {
    align-self: stretch;
    z-index: 1;
  }

  > .body {
    width: 100vw;
    min-height: 100vh;

    > .footer {
      padding-left: $ml-1;
      margin-top: 51px;
    }
  }
}
</style>
